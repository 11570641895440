<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/mes-evaluations"
        >
          <span class="brand-logo">
            <logo />
          </span>
          <h2 class="brand-text mb-0">
            {{ appName }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Logo from '@/views/components/Logo.vue'

import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    Logo,
  },
  setup() {
    // App Name
    const appName = 'Portail SQE'
    return {
      appName,
    }
  },
}
</script>

<style>

</style>
